






































import { State } from "@/store/goodsReceiptPrice.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      store: (st: any) => st.goodsReceiptPriceStore,
    }),
  },
})
export default class ModalAssetLine extends Vue {
  @Prop({ default: "", required: true, type: Boolean })
  visible!: boolean;

  store!: State;

  columns = [
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      scopedSlots: { customRender: "serialNumber" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      scopedSlots: { customRender: "type" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      scopedSlots: { customRender: "specification" },
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      scopedSlots: { customRender: "capacity" },
    },
  ];

  onCancel(): void {
    this.$emit("close");
  }
}
