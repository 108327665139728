var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "formHeader",
      attrs: {
        model: _vm.form,
        rules: _vm.formRules,
        "label-align": "left",
        "wrapper-col": { span: 12 },
        "label-col": { span: 9 }
      }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              !!_vm.form.receiveNumber
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_receive_number") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.receiveNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "receiveNumber", $$v)
                          },
                          expression: "form.receiveNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_purchase_order_number"),
                    prop: "purchaseOrderNumber"
                  }
                },
                [
                  _c("SelectAvailPo", {
                    attrs: { disabled: !!_vm.store.detail.status },
                    on: { "update:meta": _vm.onChangePo },
                    model: {
                      value: _vm.form.purchaseOrderNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "purchaseOrderNumber", $$v)
                      },
                      expression: "form.purchaseOrderNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_supplier_name") } },
                [
                  _c("a-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.supplierName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supplierName", $$v)
                      },
                      expression: "form.supplierName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_branch"),
                    prop: "branchWarehouse"
                  }
                },
                [
                  _c("SelectBranch", {
                    attrs: { disabled: !!_vm.store.detail.status },
                    on: { "update:meta": _vm.onChangeBranch },
                    model: {
                      value: _vm.form.branchWarehouse,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "branchWarehouse", $$v)
                      },
                      expression: "form.branchWarehouse"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_receive_date"),
                    prop: "receiveDate"
                  }
                },
                [
                  _c("a-date-picker", {
                    staticClass: "w-100",
                    attrs: {
                      format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                      "show-time": "",
                      "disabled-date": _vm.receiveDateRules
                    },
                    model: {
                      value: _vm.form.receiveDate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "receiveDate", $$v)
                      },
                      expression: "form.receiveDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_term_of_payment"),
                    prop: "termOfPayment"
                  }
                },
                [
                  _c("SelectTermOfPayment", {
                    model: {
                      value: _vm.form.termOfPayment,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "termOfPayment", _vm._n($$v))
                      },
                      expression: "form.termOfPayment"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: { label: _vm.$t("lbl_currency"), prop: "currencyCode" }
                },
                [
                  _c("SelectCurrency", {
                    on: { "update:meta": _vm.onChangeCurrency },
                    model: {
                      value: _vm.form.currencyCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "currencyCode", $$v)
                      },
                      expression: "form.currencyCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.currencyCode !== "IDR",
                      expression: "form.currencyCode !== 'IDR'"
                    }
                  ],
                  attrs: {
                    label: _vm.$t("lbl_currency_rate"),
                    prop: "currencyRate"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      min: 0,
                      parser: _vm.reverseFormatNumber,
                      formatter: _vm.formatterNumber,
                      precision: _vm.storeBaseDecimalPlace
                    },
                    model: {
                      value: _vm.form.currencyRate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "currencyRate", _vm._n($$v))
                      },
                      expression: "form.currencyRate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_tax_calculation"),
                    prop: "taxType",
                    "label-align": "right"
                  }
                },
                [
                  _c("SelectTaxCalculation", {
                    attrs: { disabled: !!_vm.store.detail.status },
                    on: { change: _vm.onChangeTaxCalc },
                    model: {
                      value: _vm.form.taxType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "taxType", $$v)
                      },
                      expression: "form.taxType"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_supplier_do_number"),
                    prop: "supplierDeliveryOrderNo",
                    "label-align": "right"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { disabled: !!_vm.store.detail.status },
                    model: {
                      value: _vm.form.supplierDeliveryOrderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supplierDeliveryOrderNo", $$v)
                      },
                      expression: "form.supplierDeliveryOrderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_supplier_bill_address"),
                    prop: "supplierBillToAddress",
                    "label-align": "right"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "allow-clear": "",
                        "show-search": "",
                        "dropdown-match-select-width": false,
                        "option-filter-prop": "children",
                        "filter-option": _vm.useLocalFilter
                      },
                      model: {
                        value: _vm.form.supplierBillToAddress,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "supplierBillToAddress", $$v)
                        },
                        expression: "form.supplierBillToAddress"
                      }
                    },
                    _vm._l(_vm.billAddressOptions, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.key, attrs: { value: item.value } },
                        [
                          _c("a-tooltip", [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(" " + _vm._s(item.label || "-") + " ")]
                            ),
                            _vm._v(" " + _vm._s(item.label || "-") + " ")
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              !!_vm.form.journalNo
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_journal_number"),
                        "label-align": "right"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "generaljournal.detail",
                              params: {
                                id: _vm.form.journalId
                              }
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            { staticClass: "p-0", attrs: { type: "link" } },
                            [_vm._v(" " + _vm._s(_vm.form.journalNo) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.form.createdBy
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_created_by"),
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.createdBy,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "createdBy", $$v)
                          },
                          expression: "form.createdBy"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.form.status
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_status")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.status,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_description"),
                    prop: "description",
                    disabled: !!_vm.store.detail.status,
                    "label-align": "right"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "extra",
                      fn: function() {
                        return [
                          _c("character-length", {
                            attrs: { value: _vm.form.description }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }