









































































































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectTaxCalculation from "@/components/custom/select/SelectTaxCalculation.vue";
import SelectTermOfPayment from "@/components/custom/select/SelectTermOfPayment.vue";
import { SelectAvailPo } from "@/components/GoodsReceiptChecklist";
import {
  useContactData,
  useCurrency,
  useDisabledFromTomorrow,
  useLocalFilter,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { StorageKeys } from "@/models/constant/enums/storage.enum";
import { MAX_LENGTH_CHARACTER, ONE } from "@/models/constant/global.constant";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { DetailContactDataDto } from "@/models/interface/contact-data";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { PurchaseOrderResponseDto } from "@/models/interface/purchase-order";
import { AccountingCurrencyResponseDTO } from "@/models/interface/settings.interface";
import { FormValue, State } from "@/store/goodsReceiptPrice.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { AddressDataDto } from "@interface/contact-data";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

@Component({
  components: {
    SelectAvailPo,
    SelectBranch,
    SelectTermOfPayment,
    SelectCurrency,
    SelectTaxCalculation,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
      store: (st: any) => st.goodsReceiptPriceStore,
    }),
    ...mapGetters({
      form: "goodsReceiptPriceStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      autofillFromPo: "goodsReceiptPriceStore/autofillFromPo",
      calculateLines: "goodsReceiptPriceStore/calculateLines",
    }),
    ...mapMutations({
      setForm: "goodsReceiptPriceStore/setForm",
    }),
  },
})
export default class FormHeader extends Vue {
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  useLocalFilter = useLocalFilter;

  @Ref("formHeader")
  formHeader!: FormModel;

  storeBaseDecimalPlace!: number;
  store!: State;
  form!: FormValue;
  setForm!: (payload: Partial<FormValue>) => void;
  autofillFromPo!: (poId: string) => void;
  calculateLines!: () => void;

  poDate: Moment | null = null;

  formRules = {
    purchaseOrderNumber: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    supplierName: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    branchWarehouse: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    receiveDate: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    supplierDeliveryOrderNo: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    supplierBillToAddress: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    taxType: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error").toString(),
      },
    ],
    description: [
      {
        max: MAX_LENGTH_CHARACTER,
        message: this.$t("lbl_x_character_length_exceed_y", {
          x: this.$t("lbl_note"),
          y: MAX_LENGTH_CHARACTER,
        }),
      },
    ],
  };

  billAddressOptions: Array<Option<AddressDataDto>> = [];

  mounted(): void {
    // get bill address options when open existing document
    if (this.store.detail.status) {
      this.getDetailContact(this.form.supplierId, ({ addressDataList }) => {
        this.setBillAddress(addressDataList);
      });
    }
  }

  onChangePo(opt: Option<PurchaseOrderResponseDto> | undefined): void {
    this.poDate = null;
    this.setForm({
      purchaseOrderId: opt?.value || "",
      purchaseOrderNumber: opt?.label,
      termOfPayment: undefined,
      supplierBillToAddress: "",
      receiveItems: [],
    });
    if (opt && opt.meta && opt.meta) {
      this.poDate = moment(opt.meta.date);
      this.autofillFromPo(opt.meta.id);
      this.getDetailContact(opt.meta.supplierId, ({ addressDataList, top }) => {
        this.setBillAddress(addressDataList);
        this.setForm({ termOfPayment: top });
      });
    }
  }

  getDetailContact(
    contactId: string,
    cb: (res: DetailContactDataDto) => void
  ): void {
    const { findOne } = useContactData();
    findOne(contactId).then(cb);
  }

  setBillAddress(data: Array<AddressDataDto>): void {
    const { toBillToAddressOptions } = useContactData();
    this.billAddressOptions = toBillToAddressOptions(data);
  }

  onChangeBranch(e: Option<DataWarehouseBranch> | undefined): void {
    this.setForm({
      branchWarehouse: e?.label || "",
      branchWarehouseId: e?.value || "",
    });
  }

  onChangeCurrency(
    option: Option<AccountingCurrencyResponseDTO> | undefined
  ): void {
    this.setForm({
      currencyCode: option?.meta?.currencyCode || "",
      currencyRate: ONE,
    });
    this.setCurrencyRate();
  }

  setCurrencyRate(): void {
    const { findConversion } = useCurrency();
    const to: string = this.form.currencyCode || "";
    const from: string = localStorage.getItem(StorageKeys.BASE_CURRENCY) || "";
    if (!to || !from) return;
    findConversion(from, to).then(({ data }) => {
      const [curr] = data;
      this.setForm({
        currencyRate: curr?.rate || ONE,
      });
    });
  }

  onChangeTaxCalc(value: TAX_CALCULATION | undefined): void {
    this.setForm({
      taxType: value,
    });
    this.calculateLines();
  }

  receiveDateRules(curr: Moment): boolean {
    if (!this.poDate) return useDisabledFromTomorrow(curr);
    return (
      curr.isBefore(this.poDate.startOf("day")) || useDisabledFromTomorrow(curr)
    );
  }
}
