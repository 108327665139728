import { generateUUID } from "@/helpers/uuid";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import {
  TruckingSalesOrderFormDto,
  TruckingSalesOrderRequestDto,
  TruckingSalesOrderUpdateRequestDto,
} from "@/models/interface/trucking-sales-order";
import { LabelInValue } from "@/types";
import Decimal from "decimal.js-light";
import moment from "moment";

export class TruckingSalesOrderMapper {
  static formModelToSalesOrderRequestDto(
    dto: TruckingSalesOrderFormDto,
    total: Decimal,
    totalTax: Decimal,
    totalDiscount: number,
    grandTotal: number
  ): TruckingSalesOrderRequestDto {
    const SALES_ORDER_TRUCKING = "Trucking";

    return {
      isLuxury: dto.isLuxury,
      date: dto.date?.format() || "",
      branchWarehouseId: dto.branch?.key || "",
      salesType: SALES_ORDER_TRUCKING,
      customerId: dto.customer?.key || "",
      customerShipToAddress: dto.shippingAddress?.key || "",
      customerBillToAddress: dto.billingAddress?.key || "",
      termOfPayment: dto.termOfPayment,
      currency: dto.currency?.label || "",
      currencyRate: dto.currencyRate,
      taxCalculation: (dto.taxCalculation?.key as TAX_CALCULATION) || "",
      notes: dto.notes,
      state: dto.status,
      total: total.toString(),
      totalTax: totalTax.toString(),
      totalDiscount: totalDiscount,
      grandTotal: grandTotal,
      salesOrderLines: dto.salesOrderLines.map(line => ({
        inclusiveTaxRateId: line.inclusiveTax?.key ?? null,
        id: line.id,
        assetId: String(line.unitCode?.key) || "",
        uomId: line.unitOfMeasurementId,
        qty: line.qty,
        price: line.price,
        serialNumber: line.serialNumber,
        taxId: line.taxCode?.key || "",
        subTotal: line.subtotal.toNumber(),
        salesName: line.salesName,
        documentReference: line.documentReference,
        description: line.description,
      })),
    };
  }

  static formModelToSalesOrderUpdateRequestDto(
    dto: TruckingSalesOrderFormDto,
    total: Decimal,
    totalTax: Decimal,
    totalDiscount: number,
    grandTotal: number
  ): TruckingSalesOrderUpdateRequestDto {
    const updateDto = TruckingSalesOrderMapper.formModelToSalesOrderRequestDto(
      dto,
      total,
      totalTax,
      totalDiscount,
      grandTotal
    );

    return {
      ...updateDto,
      deletedSalesOrderLineIds: dto.deletedSalesOrderLineIds,
    };
  }

  static salesOrderResponseDtoToFormModel(
    dto: SalesOrderResponseDto
  ): TruckingSalesOrderFormDto {
    return {
      documentNumber: dto.documentNumber,
      documentReference: dto.documentReference,
      currencyRate: dto.currencyRate,
      date: moment(dto.date),
      notes: dto.notes,
      status: dto.states,
      termOfPayment: dto.termOfPayment,
      billingAddress: {
        key: dto.customerBillToAddress,
        label: dto.customerBillToAddress,
      },
      branch: {
        key: dto.branchWarehouseId,
        label: dto.branchWarehouse,
      },
      currency: {
        key: dto.currency,
        label: dto.currency,
      },
      customer: {
        key: dto.customerId,
        label: dto.customerName,
      },
      shippingAddress: {
        key: dto.customerShipToAddress,
        label: dto.customerShipToAddress,
      },
      taxCalculation: {
        key: dto.taxCalculation,
        label: dto.taxCalculation,
      },
      deletedSalesOrderLineIds: [],
      salesOrderLines: dto.salesOrderLines.map((line, index) => {
        let inclusiveTax: LabelInValue | undefined = undefined;
        if (line.inclusiveTaxRateId && line.inclusiveTaxRateName) {
          inclusiveTax = {
            label: line.inclusiveTaxRateName,
            key: line.inclusiveTaxRateId,
          };
        }

        return {
          inclusiveTax,
          inclusiveTaxRate: line.inclusiveTaxRate ?? 0,
          description: line.description,
          rowId: generateUUID(),
          id: line.id,
          brand: line.merk,
          discount: line.discountValue,
          documentReference: line.documentReference,
          dpp: new Decimal(line.taxableValue),
          equipment: line.productName,
          price: line.price,
          qty: line.qty,
          salesName: line.salesName,
          serialNumber: line.serialNumber,
          specification: line.specification,
          subtotal: new Decimal(line.subTotal),
          taxAmount: new Decimal(line.taxValue),
          taxCode: {
            key: line.taxCodeId,
            label: line.taxCode,
          },
          taxRate: line.taxRate,
          type: line.type,
          unitOfMeasurement: line.uomName,
          unitOfMeasurementId: line.uomId,
          key: index,
          unitCode: {
            key: line.assetId,
            label: line.unitCode,
          },
          loadingRates: false,
          loadingTaxCodes: false,
          loadingTrucks: false,
          rateOptions: [],
          taxCodeOptions: [],
          truckOptions: [],
          grossValue: new Decimal(0),
        };
      }),
      isLuxury: dto.isLuxury,
      taxBaseVariable: 1,
    };
  }
}
