

































import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      totalDiscount: "goodsReceiptPriceStore/getTotalDiscount",
      totalBaseAmount: "goodsReceiptPriceStore/getTotalBaseAmount",
      totalTax: "goodsReceiptPriceStore/getTotalTax",
      grandTotal: "goodsReceiptPriceStore/getGrandTotal",
    }),
  },
})
export default class DisplayTotal extends Vue {
  totalDiscount!: number;
  totalBaseAmount!: number;
  totalTax!: number;
  grandTotal!: number;
}
