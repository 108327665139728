



































import { SearchBuilder } from "@/builder";
import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useTax } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { AccountingTaxResponseDto } from "@/models/interface/master-tax";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectTaxVatOut extends Vue {
  @Prop({ required: true })
  value!: string | LabelInValue;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  options: Option<AccountingTaxResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams(this.buildQuery());
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findCollections, toOptions } = useTax();
    this.loading = true;
    findCollections(params)
      .then((res: Pagination<AccountingTaxResponseDto>) => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string | LabelInValue | undefined): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useTax();
    const params = new RequestQueryParams();
    const query: string[] = [this.buildQuery()];
    const filtered: string = filterBy({
      description: val,
    });
    if (filtered) {
      query.push(filtered);
    }
    params.search = query.join(new SearchBuilder().AND);
    this.fetchOptions(params);
  }

  findOption(
    value: string | LabelInValue | undefined
  ): Option<AccountingTaxResponseDto> | undefined {
    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    }

    return this.options.find(e => e.value === value?.key);
  }

  /**
   * @description build default query
   */
  buildQuery(): string {
    const builder = new SearchBuilder();

    return builder.push(["taxType", "VAT_OUT"]).build();
  }
}
